import { EventAggregator } from 'aurelia-event-aggregator';
import { ServiceStationStorageService } from './../../services/service-station-storage-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject, LogManager } from 'aurelia-framework';
import { ServiceStationService } from 'services/service-station-service';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("service-station-storage-with-children");

export interface ServiceStationStorageWithChildren extends Models.ServiceStationStorage {
  children?: Array<Models.ServiceStationStorage>;
  _newFormVisible?: boolean;
  _newForm?: Models.ServiceStationStorage;
  _editFormVisible?: number;
}

@autoinject
export class ServiceStationStorages {
  private servicestation: Models.ServiceStation;
  private storages: Array<ServiceStationStorageWithChildren>;
  
  private storageNewFormVisible: any;
  private storageEditFormVisible: any;
  private storage: Models.ServiceStationStorage;

  private childStorageNewFormVisible: any;
  private childStorageEditFormVisible: any;
  private childStorage: Models.ServiceStationStorage;
  
  private nextTabIndex: number = null;

  constructor(
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private storageService: ServiceStationStorageService,
    private eventAggregator: EventAggregator) {
  }

  activate(params) {
    this.serviceStationService.get(params.Id)
      .then(serviceStation => {
        this.servicestation = serviceStation;
      }).catch(err => this.errorService.handleError(err));
    

    this.getStorages(params.Id);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private canDeactivate() {
    // TODO add proper guards
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);    
  }

  getStorages(serviceStationId: number) {
    this.storageService.getAll(`?$filter=ServiceStationId eq ${serviceStationId}&$orderby=Name`)
      .then(res => {

        const storages: ServiceStationStorageWithChildren[] = [];
        const storagesSorted = res.sort((a, b) => a.ParentId ? 1 : -1);
        for (const storage of storagesSorted) {
          if (storage.ParentId) {
            const parentStorage = storages.find(it => it.Id === storage.ParentId)
            if (parentStorage.children) {
              parentStorage.children.push(storage);
            } else {
              parentStorage.children = [storage];
            }
          } else {
            storages.push(storage);
          }
        }

        logger.debug('Storages', storages);

        this.storages = storages.sort((a, b) => a.Name.localeCompare(b.Name));
      })
      .catch(err => this.errorService.handleError(err));
  }

  addStorage() {
    this.storageNewFormVisible = true;
    this.storageEditFormVisible = null;

    this.storage = new Models.ServiceStationStorage();    
    this.storage.ServiceStationId = this.servicestation.Id;
  }

  addChildStorage(parentStorage: ServiceStationStorageWithChildren) {
    logger.debug('parent ', parentStorage);
    
    parentStorage._newFormVisible = true;
    parentStorage._newForm = new Models.ServiceStationStorage();
    parentStorage._newForm.ServiceStationId = this.servicestation.Id;
    parentStorage._newForm.ParentId = parentStorage.Id;
  }

  createStorage() {
    this.storageService.post(this.storage)
    .then(res => {
      this.toastService.showSuccess('storage.created');
      this.getStorages(this.servicestation.Id);
      this.storageNewFormVisible = false;
    });
  }
  
  createChildStorage(childStorage: Models.ServiceStationStorage) {
    this.storageService.post(childStorage)
    .then(res => {
      this.toastService.showSuccess('storage.created');
      this.getStorages(this.servicestation.Id);
    });
  }

  //updateStorage(storage: Models.ServiceStationStorage) {
  updateStorage(storage: any) {
    delete storage.ServiceStationStorageType;

    // Delete children and helper-fields
    delete storage.children;
    delete storage._newForm;
    delete storage._newFormVisible;
    delete storage._editFormVisible;

    this.storageService.put(storage, storage.Id)
    .then(res => {
      this.toastService.showSuccess("storage.updated");
      this.getStorages(this.servicestation.Id);
      this.storageEditFormVisible = null;
    });
  }

  deleteStorage(storageId) {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
      this.storageService.delete(storageId)
        .then(res => {
          this.getStorages(this.servicestation.Id);
          this.toastService.showSuccess("storage.deleted");
        })
        .catch(err => {
          // special handling of error here, because most likely, the API is
          // trying to tell us the storage is not empty and therefore cannot
          // be deleted
          this.toastService.showError("storage.deletefailed");
        });
      }
    );   
  }
}
