import { ErrorService } from 'services/error-service';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ServiceStationService } from 'services/service-station-service';

@autoinject
export class ServiceStationList {
  protected tableData: Array<Models.ServiceStation>;
  protected ready: boolean = false;
  protected activeTableRow: number;

  constructor(
    private router: Router,
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService
  ) {}

  private async getAllServiceStations() {
    try {
      this.tableData = await this.serviceStationService.getAll('$orderby=Name&$expand=ErpSystem');
      this.ready = true;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected attached() {
    void this.getAllServiceStations();
  }

  protected gotoDetailView(id: number) {
    this.activeTableRow = id;
    this.router.navigateToRoute('service-station-main', { Id: id });
  }
}
