import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { ServiceStatusService } from "services/service-status-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class ServiceStatusNew {
  private servicestatus: Models.ServiceStatus = new Models.ServiceStatus();

  constructor(
    private serviceStatusService: ServiceStatusService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createServiceStatus() {
    this.serviceStatusService
      .post(this.servicestatus)
      .then(res => {
        this.eventAggregator.publish("serviceStatusListReset", 1);
        this.toastService.showSuccess("servicestatus.created");
        this.router.navigateToRoute("service-status-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
