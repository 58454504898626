import { ServiceStationPipelineTypeService } from './../../services/service-station-pipeline-type-service';
import { ServiceStationPipelineService } from './../../services/service-station-pipeline-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { Router, RouterConfiguration } from 'aurelia-router';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { ServiceStationService } from 'services/service-station-service';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';

@autoinject
export class ServiceStationMain {
  private servicestation: Models.ServiceStation;

  constructor(
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private router: Router,
    private deleteDialogService: DeleteDialogService) {
  }

  private configureRouter(config: RouterConfiguration, router) {
    config.map([
      {
        route: [''],
        name: 'service-station-details',
        moduleId: PLATFORM.moduleName('./service-station-details'),
        tabindex: 0
      },
      {
        route: ['pipelines'],
        name: 'service-station-pipelines', 
        moduleId: PLATFORM.moduleName('./service-station-pipelines'),
        tabindex: 1
      },
      {
        route: ['storages'],
        name: 'service-station-storages',
        moduleId: PLATFORM.moduleName('./service-station-storages'),
        tabindex: 2
      },
      {
        route: ['prices'],
        name: 'service-station-prices',
        moduleId: PLATFORM.moduleName('./service-station-prices'),
        tabindex: 3
      },
      {
        route: ['kpis'],
        name: 'service-station-kpis',
        moduleId: PLATFORM.moduleName('./service-station-kpis'),
        tabindex: 4
      }
    ]);
  }

  activate(params) {
    this.serviceStationService.get(params.Id)
      .then(serviceStation => {
        this.servicestation = serviceStation;
      }).catch(err => this.errorService.handleError(err));
  }

  deleteServiceStation() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
      this.serviceStationService.delete(this.servicestation.Id)
        .then(res => {
          this.router.navigateToRoute('service-station-list');
          this.toastService.showSuccess("servicestation.deleted");
        });
      }
    );   
  }
}
