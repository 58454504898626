import { EventAggregator } from 'aurelia-event-aggregator';
import { ErrorService } from 'services/error-service';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ServiceStationService } from 'services/service-station-service';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';

@autoinject
export class serviceStationDetails {
  protected ready = false;
  private servicestation: Models.ServiceStation = {} as Models.ServiceStation;

  private nextTabIndex: number = null;

  constructor(
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    protected router: Router,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('SellerCode')
      .maxLength(10)
      .withMessage(this.t.tr('validation.lengthLessOrEqualToValue', { value: 10 }))
      .on(this.servicestation);
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.servicestation);
    return (await this.validationController.validate({ object: this.servicestation })).valid;
  }

  activate(params: { Id: number }) {
    this.serviceStationService
      .get(params.Id)
      .then((serviceStation) => {
        this.servicestation = serviceStation;
        this.ready = true;
      })
      .catch((err) => this.errorService.handleError(err));

    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
  }

  protected canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
  }

  protected async updateServiceStation() {
    try {
      if (!(await this.validate())) {
        return;
      }
      await this.serviceStationService.put(this.servicestation, this.servicestation.Id);
      this.toastService.showSuccess('servicestation.updated');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
