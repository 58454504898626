import { Models } from 'models/core';
import { bindable, autoinject } from 'aurelia-framework';
import { IndustryService } from 'services/industry-service';
import { CompanyService } from 'services/company-service';
import { ErpSystemService } from 'services';
import { ErpSystem } from 'models';
import { ValidationController } from 'aurelia-validation';

@autoinject
export class ServiceStationForm {
  @bindable servicestation: Models.ServiceStation;
  @bindable validationController: ValidationController;

  protected industries: Models.Industry[];
  protected companies: Models.Company[];
  protected erpSystems: ErpSystem[];

  constructor(
    private industryService: IndustryService,
    private erpSystemService: ErpSystemService,
    private companyService: CompanyService
  ) {}

  private async getAllErpSystems() {
    this.erpSystems = await this.erpSystemService.getAll();
  }

  private async getAllInustries() {
    this.industries = await this.industryService.getAll();
  }

  private async getAllCompanies() {
    this.companies = await this.companyService.getAll();
  }

  protected async attached() {
    await this.getAllInustries();
    await this.getAllErpSystems();
    await this.getAllCompanies();

    if (!this.servicestation.IndustryId) {
      this.servicestation.IndustryId = 1;
    }

    if (!this.servicestation.CompanyId) {
      this.servicestation.CompanyId = Number(
        this.companies.find((e) => e.InternalName === Models.Companies.Morenot).Id
      );
    }
  }

  public setImageId(imageIdToUpdate: string, event: any) {
    this.servicestation[imageIdToUpdate] = event.detail.id;
  }

  public deleteImageId(imageIdToUpdate: string) {
    this.servicestation[imageIdToUpdate] = null;
  }
}
