import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ServiceStationService } from 'services/service-station-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class ServiceStationNew {
  private servicestation: Models.ServiceStation = new Models.ServiceStation;
  private saving: boolean = false;

  constructor(private serviceStationService: ServiceStationService, private router: Router, private toastService: ToastService, private errorService: ErrorService) { }

  private createServiceStation() {
    this.saving = true;
    this.serviceStationService.post(this.servicestation)
      .then((createdServiceStation) => {
        this.toastService.showSuccess('servicestation.created');
        this.router.navigateToRoute('service-station-main', { Id: createdServiceStation.Id });
        this.saving = false;
      }).catch((err) => {
        this.saving = false;
        this.errorService.handleError(err);
      });
  }
}
