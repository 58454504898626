import { EventAggregator } from 'aurelia-event-aggregator';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ServiceStationService } from 'services/service-station-service';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';
import { NetTypeService } from 'services/net-type-service';
import { SpecialProductTypeService } from 'services/special-product-type-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ServiceStationKpiService } from 'services/service-station-kpi-service';
import * as moment from 'moment';

@autoinject
export class ServiceStationKpis {
  private nextTabIndex: number = null;
  private serviceStation: Models.ServiceStation;
  private kpis: Array<Models.ServiceStationKpi>;
  private kpi: Models.ServiceStationKpi;
  private kpiNewFormVisible: boolean = false;
  private kpiEditFormVisible: any;
  private generatingBaseData: boolean = false;

  private year: number;
  private years: Array<object>;

  constructor(
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private serviceStationKpiService: ServiceStationKpiService,
    private netTypeService: NetTypeService,
    private specialProductTypeService: SpecialProductTypeService,
    private deleteDialogService: DeleteDialogService
  ) {
  }

  private activate(params) {
    this.serviceStationService
    .get(params.Id)
    .then((serviceStation) => {
      this.serviceStation = serviceStation;
    })
    .catch((err) => this.errorService.handleError(err));

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    // Made as objects to work with select2
    this.years = [
      { Year: moment.default().subtract(1, 'year').year() },
      { Year: moment.default().year() },
      { Year: moment.default().add(1, 'year').year() }
    ];

    this.year = moment.default().year();
    this.getKpis(params.Id);

  }

  private getKpis(serviceStationId) {
    this.serviceStationKpiService
    .getAll(`?$filter=ServiceStationId eq ${serviceStationId} and Year eq ${this.year}`)
    .then((res) => {
      this.kpis = res.map((it: any) => {

        const monthName = moment.default(it.Month, 'M').format('MMMM');

        // Monthname from moment sometimes starts with
        // uppercase letter, and sometimes don't.
        it.MonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

        return it;

      });
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private canDeactivate() {
    // TODO add proper guards
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
  }

  private setYear(event) {
    this.year = parseInt(event.detail.value);
    this.getKpis(this.serviceStation.Id);
  }

  private async createBaseData() {
    this.generatingBaseData = true;
    this.toastService.showSuccess('servicestation.kpisCreatingBaseData');
    try {
      for (let index = 0; index < 12; index++) {
        try {

          const kpi = new Models.ServiceStationKpi();
          kpi.Year = this.year;
          kpi.Month = index + 1;
          kpi.ServiceStationId = this.serviceStation.Id;

          await this.serviceStationKpiService.post(kpi);

        } catch (error) {
          this.errorService.handleError(error);
        }
      }

      this.toastService.showSuccess('servicestation.kpisBaseDataCreated');
      this.getKpis(this.serviceStation.Id);

    } catch (error) {
      this.generatingBaseData = false;
      this.toastService.showError('servicestation.kpisBaseDataFailed');
      this.errorService.handleError(error);
    }

  }

  private updateKpi(kpi: any) {
    delete kpi.MonthName;
    this.serviceStationKpiService
    .put(kpi, kpi.Id)
    .then((res) => {
      this.toastService.showSuccess('servicestation.kpiUpdated');
      this.getKpis(this.serviceStation.Id);
      this.kpiEditFormVisible = null;
    });
  }

}
