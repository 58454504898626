import { EventAggregator } from 'aurelia-event-aggregator';
import { ServiceStationPipelineTypeService } from './../../services/service-station-pipeline-type-service';
import { ServiceStationPipelineService } from './../../services/service-station-pipeline-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ServiceStationService } from 'services/service-station-service';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';

@autoinject
export class ServiceStationPipelines {
  private servicestation: Models.ServiceStation;
  private pipelines: Array<Models.ServiceStationPipeline>;

  private pipelineNewFormVisible: any;
  private pipelineEditFormVisible: any;
  private pipeline: Models.ServiceStationPipeline;

  private nextTabIndex: number = null;

  constructor(
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private pipelineTypeService: ServiceStationPipelineTypeService,
    private pipelineService: ServiceStationPipelineService,
    private eventAggregator: EventAggregator) {
  }

  activate(params) {
    this.serviceStationService.get(params.Id)
      .then(serviceStation => {
        this.servicestation = serviceStation;
      }).catch(err => this.errorService.handleError(err));
    

    this.getPipelines(params.Id);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private canDeactivate() {
    // TODO add proper guards
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);    
  }

  getPipelines(serviceStationId: number) {
    this.pipelineService.getAll(`?$filter=ServiceStationId eq ${serviceStationId}&$orderby=ServiceStationPipelineType/SortIndex,SortIndex`)
      .then(res => {
        this.pipelines = res;        
      })
      .catch(err => this.errorService.handleError(err));
  }

  addPipeline() {
    this.pipelineNewFormVisible = true;
    this.pipelineEditFormVisible = null;

    this.pipeline = new Models.ServiceStationPipeline();
    this.pipeline.ServiceStationId = this.servicestation.Id;
  }

  createPipeline() {
    this.pipelineService.post(this.pipeline)
    .then(res => {
      this.toastService.showSuccess('pipeline.created');
      this.getPipelines(this.servicestation.Id);
      this.pipelineNewFormVisible = false;
    });
  }

  updatePipeline(pipeline: Models.ServiceStationPipeline) {
    delete pipeline.ServiceStationPipelineType;

    this.pipelineService.put(pipeline, pipeline.Id)
    .then(res => {
      this.toastService.showSuccess("pipeline.updated");
      this.getPipelines(this.servicestation.Id);
      this.pipelineEditFormVisible = null;
    });
  }

  deletePipeline(pipelineId) {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
      this.pipelineService.delete(pipelineId)
        .then(res => {
          this.getPipelines(this.servicestation.Id);
          this.toastService.showSuccess('pipeline.deleted');
        })
        .catch(err => {
          // special handling of error here, because most likely, the API is
          // trying to tell us the pipeline is not empty and therefore cannot
          // be deleted
          this.toastService.showError('pipeline.deletefailed');
        });
      }
    );   
  }
}
