import { EventAggregator } from 'aurelia-event-aggregator';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ServiceStationService } from 'services/service-station-service';
import { Models } from 'models/core';
import { ToastService } from 'services/toast-service';
import { ServiceStationPriceService } from 'services/service-station-price-service';
import { NetTypeService } from 'services/net-type-service';
import { SpecialProductTypeService } from 'services/special-product-type-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ServiceStationAntifoulingPriceService } from 'services/service-station-antifouling-price-service';
import { ImpregnationTypeService } from 'services/impregnation-type-service';

@autoinject
export class ServiceStationPrices {
  private nextTabIndex: number = null;
  private serviceStation: Models.ServiceStation;
  private prices: Array<Models.ServiceStationPrice>;
  private price: Models.ServiceStationPrice;
  private priceNewFormVisible: boolean = false;
  private priceEditFormVisible: any;

  private antifoulingPrices: Array<Models.ServiceStationAntifoulingPrice>;
  private antifoulingPrice: Models.ServiceStationAntifoulingPrice;
  private antifoulingPriceNewFormVisible: boolean = false;
  private antifoulingPriceEditFormVisible: any;

  constructor(
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private serivceStationPriceService: ServiceStationPriceService,
    private netTypeService: NetTypeService,
    private specialProductTypeService: SpecialProductTypeService,
    private deleteDialogService: DeleteDialogService,
    private serviceStationAntifoulingPriceService: ServiceStationAntifoulingPriceService,
    private impregnationTypeService: ImpregnationTypeService
  ) {
  }

  private activate(params) {
    this.serviceStationService
    .get(params.Id)
    .then((serviceStation) => {
      this.serviceStation = serviceStation;
    })
    .catch((err) => this.errorService.handleError(err));

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    this.getPrices(params.Id);
    this.getAntifoulingPrices(params.Id);

  }

  private getPrices(serviceStationId) {
    this.serivceStationPriceService
    .getAll(`?$filter=ServiceStationId eq ${serviceStationId}&$expand=SpecialProductType,NetType`)
    .then((res) => {
      this.prices = res;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getAntifoulingPrices(serviceStationId) {
    this.serviceStationAntifoulingPriceService
    .getAll(`?$filter=ServiceStationId eq ${serviceStationId}&$expand=ImpregnationType`)
    .then((res) => {
      this.antifoulingPrices = res;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private canDeactivate() {
    // TODO add proper guards
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
  }

  private addPrice() {
    this.priceNewFormVisible = true;
    this.priceEditFormVisible = null;
    this.price = new Models.ServiceStationPrice();
    this.price.ServiceStationId = this.serviceStation.Id;
  }

  private setProduct(price: Models.ServiceStationPrice, event) {
    if (price.IsSpecialProductPrice) {
      price.NetTypeId = null;
      price.SpecialProductTypeId = event.detail.value;
    } else {
      price.SpecialProductTypeId = null;
      price.NetTypeId = event.detail.value;
    }
  }

  private createPrice() {
    this.serivceStationPriceService.post(this.price)
    .then((res) => {
      this.toastService.showSuccess('servicestation.priceCreated');
      this.getPrices(this.serviceStation.Id);
      this.priceNewFormVisible = false;
    });
  }

  private updatePrice(price: Models.ServiceStationPrice) {
    delete price.SpecialProductType;
    delete price.NetType;

    this.serivceStationPriceService
    .put(price, price.Id)
    .then((res) => {
      this.toastService.showSuccess('servicestation.priceUpdated');
      this.getPrices(this.serviceStation.Id);
      this.priceEditFormVisible = null;
    });
  }

  private deletePrice(priceId) {
    this.deleteDialogService.confirmBeforeDelete(() => {
      this.serivceStationPriceService
      .delete(priceId)
      .then((res) => {
        this.getPrices(this.serviceStation.Id);
        this.toastService.showSuccess('servicestation.priceDeleted');
      })
      .catch((err) => this.errorService.handleError(err));
    });
  }

  private updateServiceStationPrices() {
    this.serviceStationService
    .put(this.serviceStation, this.serviceStation.Id)
    .then((res) => {
      this.toastService.showSuccess('servicestation.pricesUpdated');
      this.getPrices(this.serviceStation.Id);
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private addAntifoulingPrice() {
    this.antifoulingPriceNewFormVisible = true;
    this.antifoulingPriceEditFormVisible = null;
    this.antifoulingPrice = new Models.ServiceStationAntifoulingPrice();
    this.antifoulingPrice.ServiceStationId = this.serviceStation.Id;
  }

  private createAntifoulingPrice() {

    const existing = this.antifoulingPrices.find(({
      ServiceStationId,
      ImpregnationTypeId
    }) => ServiceStationId == this.antifoulingPrice.ServiceStationId && ImpregnationTypeId == this.antifoulingPrice.ImpregnationTypeId);

    if (existing) {
      return this.toastService.showError('servicestation.antifoulingPriceAlreadyExist');
    }

    this.serviceStationAntifoulingPriceService
    .post(this.antifoulingPrice)
    .then((res) => {
      this.toastService.showSuccess('servicestation.antifoulingPriceCreated');
      this.getAntifoulingPrices(this.serviceStation.Id);
      this.antifoulingPriceNewFormVisible = false;
    });
  }

  private updateAntifoulingPrice(antifoulingPrice: Models.ServiceStationAntifoulingPrice) {

    const existing = this.antifoulingPrices.filter(({ ImpregnationTypeId }) => ImpregnationTypeId == antifoulingPrice.ImpregnationTypeId);
    if (existing.length > 1) {
      return this.toastService.showError('servicestation.antifoulingPriceAlreadyExist');
    }

    delete antifoulingPrice.ImpregnationType;

    this.serviceStationAntifoulingPriceService
    .put(antifoulingPrice, antifoulingPrice.Id)
    .then((res) => {
      this.toastService.showSuccess('servicestation.antifoulingPriceUpdated');
      this.getAntifoulingPrices(this.serviceStation.Id);
      this.antifoulingPriceEditFormVisible = null;
    }).catch((err) => this.errorService.handleError(err));
  }

  private deleteAntifoulingPrice(antifoulingPriceId) {
    this.deleteDialogService.confirmBeforeDelete(() => {
      this.serviceStationAntifoulingPriceService
      .delete(antifoulingPriceId)
      .then((res) => {
        this.getAntifoulingPrices(this.serviceStation.Id);
        this.toastService.showSuccess('servicestation.antifoulingPriceDeleted');
      })
      .catch((err) => this.errorService.handleError(err));
    });
  }

}
