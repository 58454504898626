import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { ServiceStatusService } from "services/service-status-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class serviceStatusDetail {
  private servicestatus: Models.ServiceStatus;

  constructor(
    private serviceStatusService: ServiceStatusService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator
  ) {}

  activate(params) {
    this.serviceStatusService
      .get(params.Id)
      .then(serviceStatus => {
        this.servicestatus = serviceStatus;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateServiceStatus() {
    this.serviceStatusService
      .put(this.servicestatus, this.servicestatus.Id)
      .then(res => {
        this.eventAggregator.publish("serviceStatusListReset", 1);
        this.toastService.showSuccess("servicestatus.updated");
        this.router.navigateToRoute("service-status-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteServiceStatus() {
    this.serviceStatusService
      .delete(this.servicestatus.Id)
      .then(res => {
        this.eventAggregator.publish("serviceStatusListReset", 1);
        this.toastService.showSuccess("servicestatus.deleted");
        this.router.navigateToRoute("service-status-list");
      })
      .catch(err => this.errorService.handleError(err));
  }
  gotoParentView(){
    this.eventAggregator.publish("serviceStatusListReset", 1);
    this.router.navigateToRoute("service-status-list");   
  }
}
